import React, { useState } from "react"
import Layout from "../components/layout"
import styled from "styled-components"

const Contact = () => {
  const [formState, setFormState] = useState({
    name: "",
    mail: "",
    phone: "",
    message: "",
  })

  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  const handleChange = e => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    })
  }

  const handleSudmit = e => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...this.state }),
    })
      .then(() => alert("Success!"))
      .catch(error => alert(error))

    e.preventDefault()
  }

  return (
    <Layout>
      <ContactWrapper>
        <h1>Nous Contacter</h1>
        <FormWrapper>
          <form
            name="contact"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={handleSudmit}
            action="/Success"
          >
            <input type="hidden" name="form-name" value="contact" />
            <div className="Wrap">
              <span class="label-input">Votre Nom et Prénom *</span>
              <input
                required
                type="text"
                name="name"
                onChange={handleChange}
                value={formState.name}
              />
            </div>
            <div className="Wrap">
              <span class="label-input">Votre E-mail</span>
              <input
                required
                type="email"
                name="mail"
                onChange={handleChange}
                value={formState.mail}
              />
            </div>
            <div className="Wrap">
              <span class="label-input">Votre Téléphone</span>
              <input
                required
                name="phone"
                onChange={handleChange}
                value={formState.phone}
              />
            </div>
            <div className="Wrap">
              <span class="label-input">Votre message</span>
              <textarea
                className="input "
                required
                name="message"
                rows="5"
                onChange={handleChange}
                value={formState.message}
              ></textarea>
            </div>
            <input className="btn" type="submit" value="Envoyer" />
          </form>
        </FormWrapper>
      </ContactWrapper>
    </Layout>
  )
}
const ContactWrapper = styled.div`
  width: 100%;
  margin: 5rem auto;

  h1 {
    text-align: center;
    color: #577ed9;
  }
`

const FormWrapper = styled.div`
  width: 80%;
  margin: 1rem auto;

  color: #577ed9;
  p {
    color: inherit;
    margin-bottom: -4px;
  }
  .Wrap {
    width: 100%;
    position: relative;
    border: 1px solid #577ed9;
    border-radius: 13px;
    padding: 10px 30px 9px 22px;
    margin-bottom: 20px;

    .label-input {
      font-size: 10px;
      color: inherit;
      line-height: 1.5;
      text-transform: uppercase;
    }

    input {
      height: 40px;
      display: block;
      width: 100%;
      background: inherit;
      line-height: 1.2;
      padding-right: 15px;
      font-size: 18px;
      outline: none;
      border: none;
    }
  }
  textarea {
    min-height: 7rem;
    max-height: 7rem;
    min-width: 100%;
    max-width: 100%;
    border: none;
    outline: none;
    background: inherit;
  }
  button {
    width: 5rem;
    height: 3rem;
    margin: 2rem;
    border-radius: 0.5rem;
    border: 0.1rem solid #c9ac8c;
    background: var(--green);
    color: var(--background-color);
    transition: 0.5s ease-in-out;
    outline: none;
  }
  button:hover {
    border: 0.1rem solid var(--green);
    color: var(--green);
    background: var(--background-color);
  }
`

export default Contact
